import { EventEmitter } from "eventemitter3";

class SingletonEmitter extends EventEmitter {
  constructor() {
    super();
  }

  static getInstance() {
    if (!SingletonEmitter.instance) {
      SingletonEmitter.instance = new SingletonEmitter();
    }
    return SingletonEmitter.instance;
  }
}

const sharedEmitter = SingletonEmitter.getInstance();
export default sharedEmitter;
