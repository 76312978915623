import { api } from "./ApiSystems";

export const getUserRamdom = async () => {
  try {
    const data = await api({}).get("/");
    console.log("getRequestUser", data);
    if (data.status === 200) {
      const response = data.data.results[0];
      const user = {
        name: `${response.name.title} ${response.name.last}`,
        role: `${response.nat}`,
        points: `${response.dob.age}`,
        avatar: `${response.picture.thumbnail}`,
      };
      return { status: true, data: user };
    }
  } catch (err) {
    return { status: false, message: err.message };
  }
};
