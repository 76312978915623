import sharedEmitter from "./EventEmitterSingleton";
import { user$, updateUser, destroyUser, getUser } from "./StatesUser";
import { api } from "./api/ApiSystems";
import { getUserRamdom } from "./api/User";

alert("demo-shared-resources mounted...");

export {
  sharedEmitter,
  user$,
  updateUser,
  destroyUser,
  getUser,
  api,
  getUserRamdom,
};
