import axios from "axios";

const BASE_URL = "https://randomuser.me/api/";

export const api = ({ url = BASE_URL }) => {
  return axios.create({
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'x-api-key': API_KEY
    },
  });
};
