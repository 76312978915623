import { BehaviorSubject } from "rxjs";

export const user$ = new BehaviorSubject({
  name: "Maria Echeberria",
  role: "Administrador 2",
  points: 1000,
});

export const updateUser = (user) => {
  try {
    user$.next(user);
  } catch (err) {
    console.log(err);
  }
};

export const getUser = () => {
  try {
    return user$.getValue();
  } catch (err) {
    console.log(err);
  }
};

export const destroyUser = () => {
  try {
    user$.next(null);
  } catch (err) {
    console.log(err);
  }
};
